import React, { useState } from "react";
import axios from "axios";

import { Grid, TextField, Modal, Typography, Box, Button, Avatar } from "@mui/material";
import { display } from "@mui/system";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

export default function Home() {
    const [data, setData] = useState({})
    const [errorMessage, setErrorMessage] = useState('')
    const [open, setOpen] = useState(false)
    const [errorOpen, setErrorOpen] = useState(false)

    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)
    const handleErrorClose = () => setErrorOpen(false)
    const code = {
        barcode: ''
    }

    const handleChange = (e) => {
        code.barcode = e.target.value
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        axios.defaults.headers.get['Content-Type'] = "application/json"
        axios.get(`https://comedor.atoyacci.com/api/user?barcode=${code.barcode}`)
        .then(response => {
          if (response.status === 200) {
            setData(response.data)
            handleOpen()
            setTimeout(() => {
                handleClose()
            }, 3000)
            e.target.reset()
          }
        })
        .catch(err => {
          setErrorOpen(true)
          setTimeout(() => {
            handleErrorClose()
        }, 3000)
          setErrorMessage(err.response.data ? err.response.data : '')
          e.target.reset()
        })
    }

  return (
      <Grid container sx={{
        display: 'grid',
        placeItems: 'center',
        width: '100vw',
        height: '100vh',
        margin: 0,
        padding:0,
      }}>
        <form onSubmit={handleSubmit}>
            <Grid item xs={8}>
            <TextField
                onChange={handleChange}
                name="barcode"
                required
                id="outlined"
                label="Barcode"
            />
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box sx={{ display: 'grid', placeItems: 'center' }}>
                        <img 
                            alt={`Photo - ${data.name}`} 
                            src={`data:image/png;base64, ${data.image}`}
                            style={{
                                width: '200px',
                                margin: '0 auto',
                                justifySelf: 'center'
                            }}
                        />
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            REGISTRO EXITOSO!
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            {data.name}
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            {data.services}
                        </Typography>
                    </Box>
                </Box>
            </Modal>
            <Modal
                open={errorOpen}
                onClose={handleErrorClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography align="center" id="modal-modal-description" sx={{ mt: 2 }}>
                        ERROR!
                    </Typography>
                    <Typography align="center" id="modal-modal-description" sx={{ mt: 2 }}>
                        {errorMessage}
                    </Typography>
                </Box>
            </Modal>
            </Grid>
            <Grid item xs={8} sx={{
                marginTop: '20px'
            }}>
                <Button type="submit"  variant="contained">
                    Registrar
                </Button>
            </Grid>
        </form>
      </Grid>
  );
}
